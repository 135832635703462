import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("mask", {
      id: "i1349719119__a",
      width: "16",
      height: "16",
      x: "0",
      y: "0",
      maskUnits: "userSpaceOnUse",
      style: {"mask-type":"alpha"}
    }, [
      _createElementVNode("path", {
        fill: "#D9D9D9",
        d: "M0 0h16v16H0z"
      })
    ], -1),
    _createElementVNode("g", { mask: "url(#i1349719119__a)" }, [
      _createElementVNode("path", {
        fill: "currentColor",
        d: "m4.267 12.667-.933-.934L7.067 8 3.334 4.267l.933-.934 3.734 3.734 3.733-3.734.933.934L8.934 8l3.733 3.733-.933.934-3.733-3.734-3.734 3.734Z"
      })
    ], -1)
  ])))
}
export default { render: render }